<template>
    <v-row justify="center" >

        <v-col cols="12">
              
        <v-data-table :loading="loading" :items="filtropacientes" :search="search" :headers="headers"
            :single-expand="singleExpand" :expanded.sync="expanded"  show-expand>
            <!-- Collapse dados-->
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="py-4 grey darken-2 white--text text-start">
                    <v-row dense >
                        
                        <v-col>
                            <p>Criado em: <b>{{ item.created_at | formatData }}</b></p>
                            <p>Criado por: <b>{{ item.created_by }}</b></p>
                            <p>Editado em: <b>{{ item.modified_at | formatData }}</b></p>
                            <p>Modificado por: <b>{{ item.modified_by }}</b></p>
                             <p v-if="item.medico!==null">Médico Responsável: Dr.(a) <b>{{ item.medico.nome }}</b></p> 
                            <p>Responsável pelo Paciente: <b>{{ item.responsavel }}</b></p>
                            <p>E-mail do Paciente: <b>{{ item.email }}</b></p>
                        </v-col>
                        <v-col>
                             <p v-if="item.operadora!==null">Operadora: <b>{{ item.operadora.nome }}</b></p>
                            <p v-if="item.plano!==null">Plano: <b>{{ item.plano.nome }}</b></p> 
                            <p>Matrícula: <b>{{ item.nr_carteirinha }}</b></p>
                            <p>Validade: <b>{{ item.validade | formatData }}</b></p>
                            <p>Telefone: <b>{{ item.telefone }}</b></p>
                            <p v-if="item.alergia !== null">Alergia:
                                <b>{{ item.alergia }}</b>
                            </p>
                            <p v-else>Alergia:
                                <b>Nenhuma declarada</b>
                            </p>
                            <p v-if="item.tiposanguineo !== null">Tipo Sanguíneo:
                                <b>{{ item.tiposanguineo.nome }}</b>
                            </p>
                            <p v-else>Tipo Sanguíneo:
                                <b>Favor cadastrar tipo sanguíneo</b>
                            </p>
                        </v-col>
                        <v-col>
                            <p>Plano Terapêutico:</p>
                            <!-- <span v-if="item.plano_terapeutico.length>0">
                                <div v-for="(i,k) in item.plano_terapeutico" :key="k">
                                   {{ i.procedimento.nome }} - {{ i.frequencia.nome }}
                                </div>
                            </span> -->
                            <v-divider class="my-2 pa-2"></v-divider>
                            <p>Equipe:</p>
                            <p v-for="i in item.equipe_multi_disciplinar" :key="i.id">
                                <b>
                                    {{ i.especialidade ? i.especialidade.nome : 'Falta cadastrar a especialidade. :/' }} -
                                    Dr(a). {{ i.nome }}

                                </b>
                            </p>
                        </v-col>
                    </v-row>
                </td>
            </template>
            pesquisa e atualiza lista
            <template v-slot:top>
                <v-row>
                    <v-col cols="4"> <v-text-field class="mt-4" v-model="data_inicio_" outlined label="Data início" type="date"
                        clearable></v-text-field></v-col>
            
                <v-col cols="4">
                    <v-text-field v-model="data_fim_" outlined label="Data fim" type="date" clearable
                        class="mt-4"></v-text-field></v-col>
                <v-row dense>
                </v-row>

               
                    <v-col cols="11">

                        <v-text-field v-model="search" label="Pesquisar" class="mx-0"></v-text-field>
                    </v-col>
                    <v-col cols="1" class="text-center">
                        <Tab :current-item="pac" @refresh_pacientes="get_pacientes()" />
                        <v-btn icon @click="get_pacientes()"><v-icon>mdi-refresh</v-icon></v-btn>
                    </v-col>
                </v-row>
            </template>
            <!-- Editar um paciente -->
            <template v-slot:[`item.editar`]="{ item }">
                <Tab :current-item="item" @refresh_pacientes="get_pacientes()" />
            </template>
            <!-- Data de nasc -->
            <template v-slot:[`item.data_nascimento`]="{ item }">
                {{ item.data_nascimento + 'T00:00:00' | formatData }}
            </template>
            <!-- Geolocalizacao -->
            
            <template v-slot:[`item.geolocalizacao`]="{ item }"  >
                <Tab :current-item="item" :changetab="3" @refresh_pacientes="get_pacientes()" v-if="item.geolocalizacao!==null" />
            </template>
        </v-data-table>
        </v-col>
      

    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
let first_name = ['João', 'Maria', 'Francisco', 'Lucas', 'Aline', 'Silvania'
    , 'Marilene', 'Rodrigo', 'Andre', 'Samanta']
let de = ['', 'de']
let last_name = ['Sa', 'Silva', 'Moura', 'Rodrigues', 'Rocha', 'Santos']
let fn = Math.floor(Math.random() * 9)
let ln = Math.floor(Math.random() * 5)
let d = Math.floor(Math.random() * 2)
let name = `${first_name[fn]} ${last_name[ln]} ${de[d]} ${last_name[ln]}`

export default {
    name: 'Pacientes',
    components: {
        Tab: () => import("./componentes/pacientes/Tab.vue"),

    },
    data: () => ({
        expanded: [],
        singleExpand: false,
        items: [],
        search: '',
        data_inicio_: '',
        data_fim_: '',
        pac: {
            id: null,
            nome: name,
            tiposanguineo: {
                id: null,
                nome: null,
            },
            cid: {
                id: 1,
                nome: "AS12",
                descricao: "Nome do CID"
            },
            cpf: "123.312.322-55",
            rg: "42454366",
            responsavel: null,
            data_nascimento: "1990-01-01",
            medico: {
                id: 13,
                username: "fsilva"
            },
            equipe_multi_disciplinar: [],
            plano: {
                id: 1,
                created_at: "2022-09-02T20:43:14.604074-03:00",
                modified_at: "2022-09-02T20:43:14.604193-03:00",
                created_by: "admin",
                modified_by: null,
                nome: "Familiar",
                operadora: 1
            },
            operadora: {
                id: 1,
                created_at: "2022-09-02T20:42:48.745963-03:00",
                modified_at: "2022-09-02T20:42:48.746042-03:00",
                created_by: "admin",
                modified_by: null,
                nome: "HUMANAS",
                cnpj: null,
                ans: "123",
                plano: "",
                cod_hc_slz: "21",
                logo: null,
                obs: ""
            },
            nr_carteirinha: "143342",
            orgao_exp: {
                id: 1,
                nome: "SSP-MA"
            },
            modalidade: {
                id: 4,
                nome: "ID - 24h"
            },
            geolocalizacao: null,
            validade: "2030-01-01"
        },
        headers: [
            { text: "Id", value: "id" },
            { text: "Nome", value: "nome" },
            { text: "Data Nascimento", value: "data_nascimento" },
            { text: "Operadora", value: "operadora.nome" },
            { text: "NR. Carteirinha", value: "nr_carteirinha" },
            { text: "Modalidade", value: "modalidade.nome" },
            { text: "CID", value: "cid.descricao" },
            { text: "Geolicalização", align: 'center', value: "geolocalizacao" },
            { text: "Editar", align: 'center', value: "editar" }
        ],
    }),
    computed: {
        ...mapGetters(['pacientes', 'loading']),
        filtropacientes() {

            let c = this.pacientes.filter((e) => {
                return this.data_inicio_ >= e.data_nascimento && this.data_fim_ <= e.data_nascimento

            })

            if (c.length == 0) {
              
                c = this.pacientes

            }
            return c
        }
    },
    methods: {
        get_pacientes() {
            let obj = {
                link: 'pacientes/listar/',
                var: 'pacientes'
            }
            this.$store.dispatch('crud_api', obj)
        },
    },
    mounted() {
        this.get_pacientes()
    },
}
</script>